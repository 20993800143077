<!--
* @description:
* @fileName shopManage.vue
* @author lxs
* @date 2022/08/30 14:21
!-->
<template>
  <div>
    <vab-query-form class="ml-10" style="padding-top: 20px">
      <vab-query-form-left-panel :span="24">
        <el-form :inline="true" :model="state.queryForm" @submit.prevent>
          <el-form-item label="手机号">
            <el-input
              class="mr-10"
              placeholder="请输入手机号"
              v-model="state.queryForm.mobile"
            ></el-input>
          </el-form-item>

          <el-form-item label="用户状态">
            <el-select
              v-model="state.queryForm.status"
              class="mr-10"
              @change="handleChangeSelect"
              placeholder="请选择状态"
            >
              <el-option
                v-for="item in state.typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="投放状态">
            <el-select
              v-model="state.queryForm.putStatus"
              class="m-2"
              @change="handleChangeThrowSelect"
              placeholder="请选择投放状态
"
            >
              <el-option
                v-for="item in state.typeThrowOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="Search" type="primary" @click="handleQueryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="24">
        <el-button type="success" @click="handleDownload" size="large">
          下载报表
        </el-button>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="loading" border :data="state.list">
      <el-table-column
        label="序号"
        prop="idNo"
        show-overflow-tooltip
        align="center"
      >
        <template #default="{ $index }">
          <div>{{ $index + 1 }}</div>
        </template>
      </el-table-column>
      <!--  票类型 展会EXHIBITION 论坛FORUM -->
      <el-table-column
        label="投放手机号"
        prop="mobile"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        label="姓名"
        prop="name"
        label-width="200"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        label="身份证号"
        prop="idNo"
        show-overflow-tooltip
        align="center"
      />
      <el-table-column
        label="用户状态"
        prop="status"
        show-overflow-tooltip
        align="center"
      >
        <template #default="{ row }">
          <div :class="row.status == 'NOT_REGISTER' ? 'text-red' : ''">
            {{
              filterUserStatus[row.status]
                ? filterUserStatus[row.status]
                : '无状态'
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="投放时间"
        prop="createTime"
        show-overflow-tooltip
        align="center"
      >
        <template #default="{ row }">
          <div>
            {{ filterTime(row.createTime) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="投放状态"
        prop="status"
        show-overflow-tooltip
        align="center"
      >
        <template #default="{ row }">
          <div :class="row.putStatus == 'FAIL' ? 'text-red' : ''">
            {{
              filterStatus[row.putStatus]
                ? filterStatus[row.putStatus]
                : '无状态'
            }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        label="失败原因"
        prop="reason"
        show-overflow-tooltip
        align="center"
      >
        <template #default="{ row }">
          <div :class="row.reason ? 'text-red' : ''">
            {{ row.reason || '暂无' }}
          </div>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination
      background
      v-if="state.total > 10"
      :current-page="state.queryForm.pageNum"
      :layout="state.layout"
      :page-size="state.queryForm.pageSize"
      :total="state.total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>
<script>
  export default { name: 'ticketsThrowDetail' }
</script>
<script setup>
  import { getCurrentInstance, reactive, ref, onActivated } from 'vue'
  import { useRoute } from 'vue-router'
  import { Search, Eleme } from '@element-plus/icons'
  import {
    checkTricksThrowDetailList,
    doloadTicket,
  } from '@/api/exhibitionManage'
  import { parseTime } from '@/utils/index'
  import Bus from '@/utils/bus'
  import { debounce } from '@/utils/debounce'
  const loading = ref(false) // 列表动画加载
  const editGroupTitle = ref('添加群组') // 编辑还是添加的弹框标题
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const filterStatus = {
    PENDING_PUT: '待投放',
    PUTTING: '投放中',
    PART_SUCCESS: '已投放(部分成功)',
    SUCCESS: '已投放',
    FAIL: '投放失败',
  }
  const filterUserStatus = {
    ALL: '全部',
    NOT_REGISTER: '未注册',
    REGISTER: '已注册',
  }
  const state = reactive({
    queryForm: {
      pageNum: 1,
      pageSize: 10,
      status: '',
      putStatus: '',
      mobile: '',
    },
    total: 0,
    list: [],
    layout: 'prev, pager, next',
    editRef: null,
    typeOptions: [
      {
        label: '全部',
        value: 'ALL',
      },
      {
        label: '未注册',
        value: 'NOT_REGISTER',
      },
      {
        label: '已注册',
        value: 'REGISTER ALL',
      },
    ],
    typeThrowOptions: [
      {
        label: '全部',
        value: '',
      },
      {
        label: '待投放',
        value: 'PENDING_PUT',
      },
      {
        label: '投放中',
        value: 'PUTTING',
      },
      {
        label: '已投放(部分成功)',
        value: 'PART_SUCCESS',
      },
      {
        label: '已投放',
        value: 'SUCCESS',
      },
      {
        label: '投放失败',
        value: 'FAIL',
      },
    ],
  })
  // 筛选时间
  const filterTime = (val) => {
    return parseTime(val)
  }

  // 下载报表
  const handleDownload = debounce(() => {
    doloadTicket(state.queryForm.batchNo).then((res) => {
      const data = res
      // 以excel文件为例
      const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
      const a = document.createElement('a')
      a.href = URL.createObjectURL(blob)
      a.download = 'Excel'
      a.click()
      URL.revokeObjectURL(a.href)
      a.remove()
    })
  })

  // 初始化数据
  const getData = async () => {
    loading.value = true
    const { data, total } = await checkTricksThrowDetailList(state.queryForm)
    state.list = data.data
    state.total = data.total
    setTimeout(() => {
      loading.value = false
    }, 500)
  }
  // 下拉事件
  const handleChangeSelect = (e) => {
    state.queryForm.status = e
  }
  // 查询
  const handleQueryData = debounce(() => {
    state.queryForm.pageNum = 1
    getData()
  })
  // 条数
  const handleSizeChange = debounce((pageSize) => {
    state.queryForm.pageSize = pageSize
    getData()
  })
  // 页码
  const handleCurrentChange = debounce((pageNum) => {
    state.queryForm.pageNum = pageNum
    getData()
  })
  // 激活组件
  onActivated(() => {
    state.queryForm.batchNo = route.query?.batchNo || ''
    state.queryForm.batchNo && getData()
  })
</script>
